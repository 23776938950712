body {
    background-image: url('footballbg.png'); /* Replace with your background image */
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    font-family: Arial, sans-serif;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
}

.quiz-container {
    position: relative;
    background-image: url('numbaselogo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    width: 500px;
    height: 700px;
    margin: 0 auto;
    text-align: center;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    position: relative;
}

h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #1db954;
    text-transform: uppercase;
}

h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #fff;
}


.options {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

li {
    font-size: 20px;
    margin: 10px;
    padding: 10px;
    border: 2px solid #1db954;
    border-radius: 10px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.7);
    transition: background 0.3s, transform 0.3s;
}

li.correct {
    background: #1db954;
    border-color: #1db954;
    color: #fff;
    box-shadow: 0 0 10px rgba(29, 185, 84, 0.7);
}

li.incorrect {
    background: #ff3e3e;
    border-color: #ff3e3e;
    color: #fff;
    box-shadow: 0 0 10px rgba(255, 62, 62, 0.7);
}

li:hover {
    background: rgba(0, 0, 0, 0.8);
    transform: scale(1.05);
    transition: background 0.3s, transform 0.3s;
}

.next-button {
    background-color: #1db954;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
}

.next-button:hover {
    background-color: #148a4e;
}

.fade-enter {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 500ms, transform 500ms;
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit-active {
    opacity: 0;
    transform: translateY(-20px);
}

.quiz-completion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.quiz-completion h2 {
    font-size: 36px;
    margin: 0;
    color: #1db954;
    text-transform: uppercase;
}

.quiz-completion p {
    font-size: 24px;
    margin: 10px 0;
    color: #fff;
}

.retry-button {
    background-color: #1db954;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
}

.retry-button:hover {
    background-color: #148a4e;
}



.quiz-container::after {
    content: "";
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 100px;
    height: 100px;
    background-image: url('numbaselogo.png');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
}

.quiz-container .language-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.quiz-container h2 {
    color: white; /* Set the text color to white */
  }

.language-text {
    font-weight: bold;
    color: #fff;
}