.landing-page-container {
    background-color: #333; /* Set the background color */
    color: white; /* Set the text color */
    padding: 20px;
    border-radius: 10px;
    margin: 0 auto;
    width: 300px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    width: 400px;
    height: 600px;
    margin: 0 auto;
    text-align: center;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    position: relative;
  }
